<template>
 <h2>Google Cloud Exchange Rates</h2>
  <div class="table-wrapper">
    <table class="fl-table">
        <thead>
          <tr>
              <th>Invoice Month</th>
              <th>Rate</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="rate in rates" :key="rate.currency_conversion_rate">
              <td>{{ formatDate(rate.month) }}</td>
              <td>$ {{ rate.currency_conversion_rate }}</td>
          </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  export default {
    data() {
      return {  
        rates: [],
      }
    },
    mounted(){
      fetch('https://google-exchange-rates-back-w2pz3m5raa-uc.a.run.app/getExchangeRates')
      .then(response => response.json()) // Parse JSON from the response
      .then(data => {
        this.rates = data; // Assign data to items
      })
      .catch(error => console.error('There was an error!', error));
    },
    methods:{
      formatDate(dateString) {
        const year = dateString.substring(0, 4);
        const month = dateString.substring(4, 6) - 1; // Subtract 1 because months are 0-indexed in JavaScript

        const date = new Date(year, month);
        const options = { year: 'numeric', month: 'long'};
        return new Intl.DateTimeFormat('en-US', options).format(date); // Adjust 'en-US' as needed
      },
    }
  }
</script>

<style>
  *{
      box-sizing: border-box;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
  }
  body{
      font-family: Helvetica;
      -webkit-font-smoothing: antialiased;
      background: rgba( 71, 147, 227, 1);
  }
  h2{
      text-align: center;
      font-size: 18px;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: white;
      padding: 30px 0;
  }

  /* Table Styles */

  .table-wrapper{
      display: flex;
      justify-content: center;
      height: 100vh; /* Make the div take the full height of the viewport */
      width: 100%;
      min-width: 300px; /* Make the div take the full width of the viewport */
      overflow-x: auto;
      padding: 0px; /* Optional: Add some padding around the table */
  }

 .fl-table {
      border-radius: 5px;
      font-size: 12px;
      font-weight: normal;
      border: none;
      border-collapse: collapse;
      width: 60%;
      min-width: 300px;
      height: auto;
      max-width: 90%;
      white-space: nowrap;
      background-color: white;
      overflow-x: visible;
  }

  .fl-table td, .fl-table th {
      text-align: center;
      /*padding: 8px;*/
  }

  .fl-table td {
      border-right: 1px solid #f8f8f8;
      font-size: 12px;
  }

  .fl-table thead th {
      color: #ffffff;
      background: #4FC3A1;
  }


  .fl-table thead th:nth-child(odd) {
      color: #ffffff;
      background: #324960;
  }

  .fl-table tr:nth-child(even) {
      background: #F8F8F8;
  }

  /* Responsive */

</style>